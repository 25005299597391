import React from 'react'
import './account.css';

function Account() {
    return (
        <div>
            <h1 className="account-text">ACCOUNT</h1>
        </div>
    )
}

export default Account
